import * as React from "react"
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import facepaint from 'facepaint'
const mq = facepaint([
    '@media(min-width: 767px)',
])

const LocationHeading = styled.h2`
  color: #76E5FF;
  font-style: italic;
`

const PersonalDescription = styled.p`
  color: #cccccc; 
`

const IntroSection = styled('section')`
  ${mq({
    marginTop:'40px',
    display:'flex',
    justifyContent:['center', 'space-around'],
    alignItems:'center',
    flexDirection:['column', 'row'],
    
    '& div':{   
      width:['default', '80%'],
      padding:['0', '0 2%'],
      minHeight:['default', '450px'],
      
      
    },
    
    '.intro-about':{
      display:'flex',
      flexDirection: 'column',
      justifyContent:'center'
    },

    '.intro-connect':{
      display: 'flex',
      justifyContent: 'space-between',
      background: '#2C3B59',
      border:'8px solid #2C3B59',

      '& .connect-four':{
        marginTop:'0',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        listStyleType:'none',
        padding: '0.8em',
      }
    },
    
})};
`

const IndexPage = () => {
  return (
      <Layout>
          <IntroSection>
              <div className="intro-about">
                  <h1>Hey, I'm Casey</h1>
                  <PersonalDescription>A web developer, designer, and seo specialist living in.</PersonalDescription>
                  <LocationHeading>Syracuse, New York.</LocationHeading>
                  <p>I empower businesses by empathizing with their users so that we create better software experiences together.</p>
              </div>
              <div className="intro-connect">
                  <StaticImage
                      src="../images/cartoon-of-casey-coding.png"
                      loading="eager"
                      alt="cartoon image of me coding."
                      placeholder="blurred"
                  />
                  <ul className="connect-four">
                      <li>
                          <a href="https://github.com/w3casey" target="_blank"  rel="noreferrer">
                              <svg xmlns="http://www.w3.org/2000/svg" width="49.133" height="39.307" viewBox="0 0 49.133 39.307">
                              <path id="github-alt" d="M19.049,91.095c0,2.139-1.116,5.64-3.757,5.64s-3.757-3.5-3.757-5.64,1.116-5.64,3.757-5.64S19.049,88.955,19.049,91.095Zm30.083-5.169a22.184,22.184,0,0,1-1.791,9.724c-3.879,7.841-14.545,7.656-22.181,7.656-7.759,0-19.059.276-23.092-7.656A21.91,21.91,0,0,1,0,85.925,17.6,17.6,0,0,1,4.248,74.3a16.023,16.023,0,0,1-.788-5A10.192,10.192,0,0,1,4.954,64c4.637,0,7.605.921,11.137,3.685a39.208,39.208,0,0,1,9.079-1.024,35.31,35.31,0,0,1,8.23.942C36.88,64.87,39.849,64,44.434,64a10.1,10.1,0,0,1,1.494,5.3,15.8,15.8,0,0,1-.788,4.934,17.636,17.636,0,0,1,3.992,11.689Zm-6.582,5.169c0-4.494-2.733-8.455-7.523-8.455a42.087,42.087,0,0,0-5.732.614,30.075,30.075,0,0,1-4.616.328,30.26,30.26,0,0,1-4.616-.328,41.4,41.4,0,0,0-5.732-.614c-4.79,0-7.523,3.961-7.523,8.455,0,8.987,8.23,10.369,15.395,10.369h4.934C34.331,101.464,42.551,100.092,42.551,91.095ZM34.1,85.455c-2.641,0-3.757,3.5-3.757,5.64s1.116,5.64,3.757,5.64,3.757-3.5,3.757-5.64S36.737,85.455,34.1,85.455Z" transform="translate(0 -64)" fill="#e8e8e8"/>
                              </svg>
                          </a>
                      </li>
                      <li>
                          <a href="https://www.linkedin.com/in/casey-woelfle-628b5820b/" target="_blank" rel="noreferrer">
                              <svg xmlns="http://www.w3.org/2000/svg" width="49.133" height="46.867" viewBox="0 0 49.133 46.867">
                                  <path id="linkedin-in" d="M11,46.877H.812V15.586H11ZM5.9,11.318A5.813,5.813,0,0,1,0,5.637,5.768,5.768,0,0,1,5.9.01a5.768,5.768,0,0,1,5.9,5.627A5.814,5.814,0,0,1,5.9,11.318ZM49.122,46.877H38.957V31.645c0-3.63-.077-8.286-5.3-8.286-5.3,0-6.108,3.944-6.108,8.024V46.877H17.378V15.586h9.769v4.268h.143C28.65,17.4,31.972,14.8,36.928,14.8c10.309,0,12.2,6.476,12.2,14.887V46.877Z" transform="translate(0 -0.01)" fill="#e8e8e8"/>
                              </svg>
                          </a>
                      </li>
                      <li>
                          <a href="https://www.twitch.tv/w3casey" target="_blank"  rel="noreferrer">
                              <svg xmlns="http://www.w3.org/2000/svg" width="49.133" height="54.288" viewBox="0 0 49.133 54.288">
                                  <path id="twitch" d="M63.208,10.971h-4.1V22.6h4.1Zm-11.257-.05h-4.1V22.558h4.1ZM34.544,0,24.31,9.693v34.9H36.592v9.693l10.235-9.693h8.191L73.443,27.144V0Zm34.8,25.209L61.16,32.962H52.97L45.8,39.748V32.962H36.592V3.879H69.348Z" transform="translate(-24.31)" fill="#e8e8e8"/>
                              </svg>
                          </a>
                      </li>
                      <li>
                          <a href="https://twitter.com/w3casey" target="_blank"  rel="noreferrer">
                              <svg xmlns="http://www.w3.org/2000/svg" width="49.133" height="39.905" viewBox="0 0 49.133 39.905">
                                  <path id="twitter" d="M44.082,58.027c.031.436.031.873.031,1.309,0,13.312-10.132,28.65-28.65,28.65A28.456,28.456,0,0,1,0,83.466a20.83,20.83,0,0,0,2.432.125,20.167,20.167,0,0,0,12.5-4.3,10.087,10.087,0,0,1-9.415-6.983,12.7,12.7,0,0,0,1.9.156,10.65,10.65,0,0,0,2.65-.343A10.071,10.071,0,0,1,2,62.236v-.125a10.141,10.141,0,0,0,4.552,1.278A10.085,10.085,0,0,1,3.429,49.921,28.623,28.623,0,0,0,24.192,60.459a11.367,11.367,0,0,1-.249-2.307,10.079,10.079,0,0,1,17.427-6.89,19.825,19.825,0,0,0,6.391-2.432,10.043,10.043,0,0,1-4.427,5.549,20.187,20.187,0,0,0,5.8-1.559,21.646,21.646,0,0,1-5.05,5.206Z" transform="translate(0 -48.082)" fill="#e8e8e8"/>
                              </svg>
                          </a>
                      </li>
                  </ul>
              </div>
          </IntroSection>
      </Layout>
  )
}

export default IndexPage
